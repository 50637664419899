<template>
  <div class="wrap">
    <Title name="参与的竞卖"></Title>

    <div class="container">
      <div class="search-box">
        <el-form inline label-width="86px">
          <el-form-item label="竞卖时间:">
            <!--  -->
            <el-date-picker
              size="mini"
              style="width: 320px"
              v-model="searchForm.time"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="状态:">
            <el-select
              v-model="searchForm.state"
              clearable
              placeholder="请选择"
              style="width: 120px"
              size="mini"
            >
              <el-option
                v-for="item in stateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="initMyAutionDealPage(0)"
              style="margin-left: 10px; padding: 7px 10px; font-size: 12px"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <div class="tabel-content">
        <el-table
          :data="tableData"
          :header-cell-style="{
            background: '#f5f5f5',
            'font-weight': 500,
            color: '#666',
            padding: '12 5px',
          }"
          style="width: 100%"
        >
          <el-table-column prop="prodname" label="产品名称" width="130">
          </el-table-column>
          <!--<el-table-column-->
          <!--prop="column_2"-->
          <!--label="所属类型">-->
          <!--</el-table-column>-->
          <el-table-column prop="prodModels" label="规格型号">
          </el-table-column>
          <el-table-column prop="amount" label="数量"> </el-table-column>
          <el-table-column prop="upPrice" label="起拍价"> </el-table-column>
          <el-table-column prop="tadAmount" label="出价"> </el-table-column>

          <el-table-column prop="isAution" label="状态">
            <template slot-scope="scope">
              {{ scope.row.isAution == "T" ? "竞卖成功" : "竞卖失败" }}
            </template>
          </el-table-column>
          <el-table-column prop="autionTime" label="竞卖时间" width="170">
          </el-table-column>
          <el-table-column label="操作" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="toBiddingDetail(scope.row.autionId)"
                >查看</el-button
              >

              <div>
                <el-button
                  type="text"
                  v-if="scope.row.orderId != null && scope.row.isAution == 'T'"
                  size="small"
                  @click="toOrderDetail(scope.row.orderId)"
                  >查看订单</el-button
                >
                <el-button
                  type="text"
                  v-if="scope.row.orderId == null && scope.row.isAution == 'T'"
                  size="small"
                  @click="completeOrderDetail(scope.row)"
                  >完善订单</el-button
                >
              </div>

              <!--<el-button type="text" size="small" @click="handleEditClick(scope.row)">修改</el-button>-->
              <!--<el-button type="text" size="small">撤回</el-button>-->
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination" v-if="pageManage.total">
          <!-- -->
          <el-pagination
            style="position: absolute; top: 16px; right: 10px"
            background
            layout="prev, pager, next"
            :total="pageManage.total"
            :page-size="pageManage.limit"
            :current-page="pageManage.page"
            @current-change="changeCurrentPage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "../../component/Title";
import { myAutionDealPage } from "../../api";
export default {
  created() {
    this.$store.commit("changeMenuType", "BUY");
    this.initMyAutionDealPage(0);
  },

  components: {
    Title,
  },
  data() {
    return {
      pageManage: {
        limit: 10,
        page: 1,
        total: 0,
      },

      stateList: [
        { value: "T", label: "竞卖成功" },
        { value: "F", label: "竞卖失败" },
      ],
      searchForm: {
        time: "",
        state: "",
      },
      tableData: [
        {
          prodname: "",
          prodModels: "",
          amount: "",
          tadAmount: "",
          upPrice: "",
          isAution: "",
          autionTime: "",
        },
      ],
    };
  },
  methods: {
    changeCurrentPage(num) {
      this.pageManage.page = num;
      this.initMyAutionDealPage(1);
    },

    //订单详情页面
    toOrderDetail(orderId) {
      this.$router.push({
        path: "/User/Paymented?customer=buyder",
        query: { orderid: orderId, orderType: "AU" },
      });
      //     #/User/ReqOrder?customer=buyder
      //todo
    },

    completeOrderDetail(row) {
      // http://192.168.1.117/lry-shop-pc-web/dist/#/home/Bidding/ConfirmOrder?pagesName=BiddingConfirmOrdera&clientId=102&autionInfoId=73
      //                      http://localhost:8080/#/home/Bidding/ConfirmOrder?pagesName=BiddingConfirmOrdera&clientId=88&autionInfoId=76
      this.$router.push({
        path: "/home/Bidding/ConfirmOrder?pagesName=BiddingConfirmOrdera",
        query: { clientId: row.clientInfoId, autionInfoId: row.autionId },
      });
    },

    //竞卖详情页面
    toBiddingDetail(autionInfoId) {
      //跳转到竞卖详情页面
      this.$router.push({
        path: "/home/BiddingDetails",
        query: { autionInfoId },
      });
    },

    //参与竞卖信息
    initMyAutionDealPage(type) {
      if (this.searchForm.time == null) {
        this.searchForm.time = "";
      }
      //
      if (type == 0) {
        this.pageManage.page = 1;
      }

      myAutionDealPage({
        isAution: this.searchForm.state,
        time: this.searchForm.time.toString(),
        page: this.pageManage.page,
        limit: this.pageManage.limit,
      }).then((res) => {
        this.tableData = res.data.items;
        this.pageManage.total = res.data.total;
      });
    },

    // 查看
    handleClick(row) {
      this.$router.push({
        path: "./MyBiddingInfo",
        query: { id: row.autionId },
      });
    },
    // 修改
    handleEditClick(row) {
      this.$router.push({
        path: "./PublishBidding?id=123",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: #fff;

  .container {
    padding: 10px;

    .search-box {
      margin-top: 12px;
    }
  }
}

/deep/.el-form-item__label {
  font-size: 12px;
}

.pagination {
  position: relative;
  height: 60px;
}

.el-table::before {
  height: 0px;
}
</style>
